import React, { useState, useHook, useEffect, useRef } from 'react';
import Header from '../Header';
import SideBar from '../SideBar';
import Footer from '../Footer';
import { get, post, put, deleteM } from "../../utility/Adapter";
import { withParams } from "../HOC";

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import { info, success } from "@pnotify/core";
import "@pnotify/core/dist/PNotify.css";
import "@pnotify/core/dist/BrightTheme.css";
import * as Confirm from "@pnotify/confirm";
import "@pnotify/confirm/dist/PNotifyConfirm.css";

import DocumentDetails from '../../components/candidates/Documents';

const CandidateDocuments = (props) => {

    const [documents, setDocuments] = useState([]);
    const [pending, setPending] = React.useState(true);
    const [candidateDocumentStatus, setCandidateDocumentStatus] = useState(0);
    const [transilators, setTransilators] = useState([]);
    const [transilator, setTransilator] = useState({});
    const [documentId, setDocumentId] = useState(0);
    const [candidateId, setCandidateId] = useState(0);
    const [candidates, setCandidates] = useState([]);
    const [candidateName, setCandidateName] = useState("");

    useEffect(function () {
        //_loadCandidates();
        _loadDocuments(1);
        _loadTransilators();

    }, []);

    var documentStatus = {
        0: 'Pending',
        1: 'Submitted',
        2: 'Waiting Proposal',
        3: 'Waiting Payment',
        4: 'Translation Progress',
        5: 'Translation Under Review',
        6: 'Completed'
    }

    const _loadTransilators = () => {
        get('admin/transilators').then(async function (response) {
            setTransilators(response.data.data);
            setPending(false);
        }).catch(function (error) {
            console.log(error);
        });
    }

    const _loadCandidates = () => {
        get('admin/candidate/profile/' + props.params.id).then(async function (response) {
            setCandidates(response.data.data);
            setPending(false);
        }).catch(function (error) {
            console.log(error);
        });
    }

    const _loadDocuments = (page) => {
        get('admin/candidate/documents/' + props.params.id)
            .then(async function (response) {
                setDocuments(response.data.data);
                setCandidateDocumentStatus(response.data.document_status);
                setTransilator(response.data.transilator);
                setPending(false);

                if (response.data.data.length > 0) {
                    setDocumentId(response.data.data[0].document_id);
                    setCandidateName(response.data.candidates[0].candidate_first_name ?? "-" + response.data.data[0].candidates[0].candidate_last_name)
                }

            }).catch(function (error) {
            });
    }

    const _assignTransilator = () => {
        var transilator = document.getElementById('transilator').value;

        if (transilator == "") {
            return null;
        }

        setTransilator(transilator);

        if (transilator) {
            var raw = JSON.stringify({
                "transilator": transilator,
                "candidate_id": props.params.id,
                "document_id": '',
            });

            post('admin/transilators/assign', raw).then(async function (response) {
                alert('Successfully assigned!');
                _loadDocuments();
            }).catch(function (error) {
            });
        }
    }

    const _approveAction = () => {
        confirmAlert({
            title: 'Confirm to approve',
            message: 'Are you sure to do this.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => _approve()
                },
                {
                    label: 'No',
                    onClick: () => { }
                }
            ]
        });
    }

    const _rejectAction = () => {
        confirmAlert({
            title: 'Confirm to reject',
            message: 'Are you sure to do this.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => _reject()
                },
                {
                    label: 'No',
                    onClick: () => { }
                }
            ]
        });
    }

    const _reject = () => {
        var raw = JSON.stringify({
            "document_id": documentId,
            "candidate_id": props.params.id
        });

        post('admin/candidates/document/reject/all', raw).then(async function (response) {
            success({
                title: "SUCCESS",
                text:
                    "Successfully rejected",
                modules: new Map([
                    [
                        Confirm,
                        {
                            confirm: true,
                            buttons: [
                                {
                                    text: "Ok",
                                    primary: true,
                                    click: notice => {
                                        notice.close();
                                    }
                                }
                            ]
                        }
                    ]
                ])
            });
            _loadDocuments();
        }).catch(function (error) {
        });
    }


    const _approve = () => {
        var raw = JSON.stringify({
            "document_id": documentId,
        });

        post('admin/candidates/document/approve', raw).then(async function (response) {
            success({
                title: "SUCCESS",
                text:
                    "Successfully approved",
                modules: new Map([
                    [
                        Confirm,
                        {
                            confirm: true,
                            buttons: [
                                {
                                    text: "Ok",
                                    primary: true,
                                    click: notice => {
                                        notice.close();
                                    }
                                }
                            ]
                        }
                    ]
                ])
            });
            _loadDocuments();
        }).catch(function (error) {
        });
    }

    const downloadAllDocuments = () => {
        window.open(
            process.env.REACT_APP_BASE_URL + 'documents/download/' + documentId,
            '_blank'
        );
    }

    return (
        <>
            <Header />

            <main>
                <div class="container-fluid">
                    <div class="row">
                        {/* Menu Start */}
                        <SideBar />
                        {/*  Menu End */}


                        {/* Page Content Start */}
                        <div class="col">
                            {/* Title and Top Buttons Start */}
                            <div class="page-title-container">
                                <div class="row g-0">
                                    <div class="col-auto mb-2 mb-md-0 me-auto">
                                        <div class="w-auto sw-md-30">
                                            <h1 class="mb-0 pb-0 display-4 text-capitalize" id="title">{candidateName ?? "Candidate"} Documents ({documents.length})</h1>
                                            <div class="text-muted font-heading text-small">List all candidate documents</div>
                                        </div>
                                    </div>


                                    <div class="col-12 col-sm-6 col-md-auto d-flex align-items-start justify-content-end mb-2 mb-sm-0 order-sm-2">

                                        {
                                            candidateDocumentStatus != 6 &&
                                            <button type="button" class="btn btn-danger me-4" onClick={() => _rejectAction()}>Reject All</button>
                                        }

                                        {
                                            candidateDocumentStatus != 6 &&
                                            <button type="button" class="btn btn-primary me-4" onClick={() => _approveAction()}>Approve All</button>
                                        }

                                        <button type="button" class="btn btn-outline-secondary mb-1 me-2" onClick={() => downloadAllDocuments()}>
                                            Download All Documents </button>


                                        <label class="mb-3 top-label" style={{ width: 200 }}>
                                            <select class="form-control required text-capitalize" id="transilator" onChange={() => _assignTransilator()}>
                                                <option value="" className="text-capitalize">--Select--</option>
                                                {
                                                    transilators.map((t, k) =>
                                                        <option value={t._id} className="text-capitalize" selected={t._id == transilator.transilator_id ? "selected" : ""}>{t.transilator_name}</option>
                                                    )
                                                }
                                            </select>
                                            <span style={{ color: 'rgb(94, 92, 92)' }}>ASSIGN TRANSLATOR</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            {/* Title and Top Buttons End */}
                            {
                                documents.map((d, k) =>
                                    <DocumentDetails d={d} key={k} candidate_id={props.params.id} />
                                )
                            }
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}

export default withParams(CandidateDocuments);

